﻿export default class URLParamsUtils {
    private static parseUrlParams() {
        return new URLSearchParams(window.location.search);
    }

    public static getAutostartEnabled(): boolean {
        const params = this.parseUrlParams();
        const autostart = params.get('autostart');
        return !!autostart && autostart === 'true';
    }

    public static getInitialSlideNumber() {
        const slideParam = this.parseUrlParams().get('slide') ?? '';
        let initialSlideNumber: number | undefined = parseInt(slideParam);
        if (isNaN(initialSlideNumber) || initialSlideNumber < 1) {
            initialSlideNumber = undefined;
        } else {
            --initialSlideNumber;
        }

        return initialSlideNumber;
    }
}
