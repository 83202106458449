<script lang="ts">
    import {Button, Column, Title} from 'Components/UI';

    import logoImage from 'Assets/Icons/ActiveGraf_Logo.png';
    import translations from 'Assets/i18n';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let message: Nullable<string> = translations.global.messages.unknownError;
    export let retryButton = false;

    function retry() {
        location.reload();
    }
</script>

<Column
    horizontalAlign="center"
    verticalAlign="center"
    gap="0.8rem">
    <img
        alt={message}
        height={100}
        src={logoImage}
        width={100} />
    <Title
        padding="0 16px"
        textAlign="center">
        {message}
    </Title>
    {#if retryButton}
        <Button on:click={retry}>{translations.global.actions.retry}</Button>
    {/if}
</Column>
